<template>
  <main class="relative w-full text-center text-bleu text-3xl leading-8">
    <div class="max-w-[1500px] w-full m-auto">
      <v-stage
        ref="stage"
        @mousedown="handleStageMouseDown"
        @touchstart="handleStageMouseDown"
        :config="configKonva"
      >
        <v-layer ref="layer">
          <v-rect ref="rect" :config="backgroundConfig" />
          <v-image
            @dragstart="handleDragStart"
            @dragend="handleDragEnd"
            @transformend="handleTransformEnd"
            v-for="item in nodeList"
            :key="item.name"
            :config="item"
          ></v-image>
          <v-transformer ref="transformer" />
        </v-layer>
      </v-stage>

      <img
        src="@/assets/images/dites.png"
        class="max-w-[300px] dektop:max-w-[500px] mb-6 m-auto desktop:-mt-12 xl:mt-2"
      />
      <p
        class="my-6 text-xl desktop:text-3xl text-left max-w-[320px] desktop:max-w-none desktop:text-center m-auto leading-5"
      >
        Personnalisez votre <b class="fond">pochoir à gâteau</b> pour<br
          class="desktop:hidden"
        />
        faire passer un message avec gourmandise.
      </p>
      <p
        class="my-6 text-xl m-auto w-fit desktop:text-3xl hidden desktop:flex items-end text-left leading-5 desktop:text-center"
      >
        <span class="bigtext">1.</span> Faites glisser la ou les formes de<br
          class="desktop:hidden"
        />
        votre choix dans la zone blanche.
      </p>
      <p
        class="my-6 text-xl m-auto w-fit desktop:text-3xl flex text-left leading-5 desktop:hidden"
      >
        <span class="bigtext">1.</span> Cliquez sur une des formes pour
        <br />l'ajouter dans la zone blanche, <br />puis déplacez la à l'endroit
        <br />que vous souhaitez.<br class="desktop:hidden" />
      </p>
      <p
        class="my-6 text-xl m-auto w-fit desktop:text-3xl flex desktop:block text-left leading-5 desktop:text-center"
      >
        <span class="bigtext">2.</span> Et si vous le souhaitez, ajoutez<br
          class="desktop:hidden"
        />
        votre texte personnalisé.
      </p>
    </div>

    <div class="px-6 desktop:hidden mb-6 relative">
      <div
        class="w-full max-w-[320px] sm:max-w-xl m-auto bg-white p-2 rounded-lg border-bleu border-2"
      >
        <!-- Using the slider component -->
        <slider ref="slider" :options="options">
          <!-- slideritem wrapped package with the components you need -->
          <slideritem
            v-for="(item, index) in someList"
            :key="index"
            style="width: 25.6%"
            ><img
              :src="`/formes/form${index + 1}.png`"
              class="w-16 cursor-pointer"
              :name="`form${index + 1}`"
              @click="addItemMobile"
          /></slideritem>
          <!-- Customizable loading -->
          <div slot="loading">loading...</div>
        </slider>
      </div>
    </div>
    <div
      class="w-full desktop:hidden max-w-[320px] sm:max-w-xl flex justify-between m-auto p-2 -mb-10 relative z-50 -mt-2 text-gray-700"
    >
      <i
        @click="slide"
        class="fa-solid fa-circle-chevron-left -ml-6 -mt-20"
      ></i>
      <i
        @click="slideNext"
        class="fa-solid fa-circle-chevron-right -mt-20 -mr-6"
      ></i>
    </div>
    <div class="w-full flex justify-center">
      <div class="xl:flex max-w-7xl m-auto gap-4 justify-between mx-4">
        <div class="flex items-center">
          <div
            id="pochoir_menu"
            class="mb-6 xl:mb-0 max-w-3xl xl:max-w-none m-auto hidden desktop:block mr-4"
          >
            <ul
              class="grid grid-cols-10 xl:grid-cols-2 relative z-50 gap-4 items-center"
            >
              <li class="mb-2">
                <img
                  src="@/assets/images/formes/form1.png"
                  class="w-16 cursor-pointer"
                  name="form1"
                />
              </li>
              <li class="mb-2">
                <img
                  src="@/assets/images/formes/form2.png"
                  class="w-16 cursor-pointer"
                  name="form2"
                />
              </li>
              <li class="mb-2">
                <img
                  src="@/assets/images/formes/form3.png"
                  class="w-16 cursor-pointer"
                  name="form3"
                />
              </li>
              <li class="mb-2">
                <img
                  src="@/assets/images/formes/form4.png"
                  class="w-16 cursor-pointer"
                  name="form4"
                />
              </li>
              <li class="mb-2">
                <img
                  src="@/assets/images/formes/form5.png"
                  class="w-16 cursor-pointer"
                  name="form5"
                />
              </li>
              <li class="mb-2">
                <img
                  src="@/assets/images/formes/form6.png"
                  class="w-16 cursor-pointer"
                  name="form6"
                />
              </li>
              <li class="mb-3">
                <img
                  src="@/assets/images/formes/form7.png"
                  class="w-16 cursor-pointer"
                  name="form7"
                />
              </li>
              <li class="mb-3">
                <img
                  src="@/assets/images/formes/form8.png"
                  class="w-16 cursor-pointer"
                  name="form8"
                />
              </li>
              <li>
                <img
                  src="@/assets/images/formes/form9.png"
                  class="w-16 cursor-pointer"
                  name="form9"
                />
              </li>
              <li>
                <img
                  src="@/assets/images/formes/form10.png"
                  class="w-16 cursor-pointer"
                  name="form10"
                />
              </li>
            </ul>
          </div>
          <img
            src="@/assets/images/fleche.png"
            class="hidden w-12 -mr-6 ml-2 xl:block"
          />
        </div>
        <div class="w-full text-center xl:hidden">
          <img src="@/assets/images/fleche2.png" class="m-auto w-10 -mt-3" />
        </div>
        <div class="mx-4 bg-white border-bleu rounded-lg border-2 p-1">
          <div
            ref="pochoir"
            id="pochoir"
            class="relative mb-6 xl:mb-0 max-w-xl rounded-lg desktop:max-w-3xl xl:max-w-none m-auto"
          >
            <textarea
              id="autoresizing"
              v-model="text"
              @input="limitTextLength"
              @click="removeplaceholder"
              class="absolute border-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 uppercase text-bleu text-[45px] leading-[54px] z-50 w-full text-center outline-none font-[800]"
            >
VOTRE TEXTE ICI</textarea
            >
          </div>
        </div>

        <div>
          <img
            src="@/assets/images/2bubbles.png"
            class="w-[200px] xl:block hidden"
          />
        </div>
      </div>
    </div>

    <div
      class="w-full text-left max-w-xs desktop:max-w-xl pt-4 m-auto text-xl flex justify-between"
    >
      <div>
        <button type="button" @click="clearAll()" class="pr-6">
          Effacer tout
        </button>
        <button
          v-if="selectedShapeName"
          type="button"
          @click="clearSelection()"
        >
          Effacer la sélection
        </button>
      </div>
      <div v-if="text != 'VOTRE TEXTE ICI'">
        {{ text.length }}/ 30 caractères
      </div>
    </div>
    <img src="@/assets/images/2bulles2.png" class="m-auto xl:hidden" />
    <div class="relative w-fit m-auto my-4 xl:my-9">
      <button class="button bleu" @click="exportToPdf()">
        JE TÉLÉCHARGE MON POCHOIR
      </button>
    </div>
    <div class="px-8 text-bleu text-xs mobile:text-sm text-center">
      *Etude OpinionWay du 6 au 7 mai 2024, 1097 répondants, catégorie sucre
    </div>
  </main>
</template>
<script>
import { slider, slideritem } from "vue-concise-slider";
import { jsPDF } from "jspdf";

export default {
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.textarea = document.querySelector("#autoresizing");
    this.textarea.addEventListener("input", this.autoResize, false);

    window.addEventListener("resize", this.handleResize);
    addEventListener(
      "dragend",
      (event) => {
        event.preventDefault();
        this.addItem(event);
      },
      false
    );
    this.load();
  },
  data() {
    return {
      textarea: null,
      text: "VOTRE TEXTE ICI",
      backgroundConfig: {},
      number: 1,
      numb: 0,
      nodeList: [],
      image: null,
      width: undefined,
      height: undefined,
      selectedShapeName: "",
      configKonva: {},
      forms: [],
      someList: [
        {
          html: "slide1",
          style: {
            background: "#1bbc9b",
          },
        },
        {
          html: "slide2",
          style: {
            background: "#4bbfc3",
          },
        },
        {
          html: "slide3",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide4",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide5",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide6",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide7",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide8",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide9",
          style: {
            background: "#7baabe",
          },
        },
        {
          html: "slide10",
          style: {
            background: "#7baabe",
          },
        },
      ],
      options: {
        pagination: true,
        currentPage: 0,
        infinite: 4,
        slidesToScroll: 4,
        loop: true,
      },
    };
  },
  components: {
    slider,
    slideritem,
  },
  methods: {
    limitTextLength() {
      if (this.text.length > 30) {
        this.text = this.text.slice(0, 30);
      }
    },
    autoResize() {
      this.textarea.style.height = "auto";
      this.textarea.style.height = `${this.textarea.scrollHeight + 5}px`;
    },
    removeplaceholder() {
      if (this.text === "VOTRE TEXTE ICI") {
        this.text = "";
      }
    },
    handleResize() {},
    async exportToPdf() {
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      stage.toDataURL({
        pixelRatio: 2,
      });

      if (this.text === "VOTRE TEXTE ICI") {
        this.text = "";
      }

      this.text = this.text.toUpperCase();
      this.selectedShapeName = "";
      this.updateTransformer();

      setTimeout(() => {
        let pdf = new jsPDF({
          orientation:
            stage.width() > stage.height() ? "landscape" : "portrait",
          unit: "px",
          format: [stage.width(), stage.height()],
          hotfixes: ["px_scaling"],
          userUnit: 2,
        });

        pdf.addFont("/ariblk.ttf", "ariblk", "regular");
        pdf.setFont("ariblk");

        const pochoir = document.getElementById("pochoir");

        pdf.html(pochoir, {
          callback: (pdf) => {
            pdf.deletePage(2);
            pdf.save("pochoir.pdf");
            if (this.text === "") {
              this.text = "VOTRE TEXTE ICI";
            }
          },
        });
      }, 100);
    },
    slideNext() {
      this.$refs.slider.$emit("slideNext");
    },
    slide() {
      this.$refs.slider.$emit("slidePre");
    },
    clearSelection() {
      const transformerNode = this.$refs.transformer.getNode();
      const currentNode = transformerNode.nodes()[0];
      if (!currentNode) return;
      const index = this.nodeList.findIndex(
        (r) => r.name === currentNode.name()
      );
      if (index === -1) return;
      this.nodeList.splice(index, 1);

      this.selectedShapeName = "";
      this.updateTransformer();
    },
    clearAll() {
      this.nodeList = [];
      this.selectedShapeName = "";
      this.updateTransformer();
      this.text = "VOTRE TEXTE ICI";
    },
    load() {
      const pochoirElement = this.$refs.pochoir;
      const width = pochoirElement.offsetWidth;
      const height = pochoirElement.offsetHeight;

      this.configKonva = {
        container: "pochoir",
        width: width,
        height: height - 5,
      };

      this.backgroundConfig = {
        x: 0,
        y: 0,
        fill: "white",
        width: width,
        height: height - 5,
      };
    },
    save() {
      localStorage.setItem("storage", JSON.stringify(this.nodeList));
      localStorage.setItem("number", this.number);
    },
    handleStageMouseDown(e) {
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = "";
        this.updateTransformer();
        return;
      }

      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const form = this.nodeList?.find((r) => r.name === name) ?? null;

      if (form) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = "";
      }
      this.updateTransformer(e, name);
    },
    updateTransformer() {
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne("." + selectedShapeName);
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        transformerNode.nodes([selectedNode]);
      } else {
        transformerNode.nodes([]);
      }
    },
    handleTransformEnd(e) {
      const form = this.nodeList?.find(
        (r) => r.name === this.selectedShapeName
      );

      form.x = e.target.x();
      form.y = e.target.y();
      form.rotation = e.target.rotation();
    },
    handleDragStart() {
      this.isDragging = true;
    },
    handleDragEnd() {
      this.isDragging = false;
    },
    async addItem(e, position = undefined) {
      const elementName = e.target.getAttribute("name");
      const pochoirBounds = this.$refs.pochoir.getBoundingClientRect();

      const pos = position ?? {
        x: e.clientX - pochoirBounds.left,
        y: e.clientY - pochoirBounds.top,
      };

      const image = new window.Image();
      image.src = `/formes/${elementName}.png`;
      if (elementName === "form5") {
        image.width = 80;
        image.height = 58;
      } else {
        image.width = 80;
        image.height = 80;
      }

      const node = {
        rotation: 0,
        x: 10,
        y: 10,
        width: image.width,
        height: image.height,
        image: image,
        name: `shape${this.number}`,
        draggable: true,
        ...pos,
      };

      this.nodeList.push(node);

      await this.$nextTick();

      this.selectedShapeName = node.name;
      this.updateTransformer();

      this.number++;
    },
    addItemMobile(e) {
      const pos = {
        x: 20,
        y: 20,
      };
      this.addItem(e, pos);
    },
    clearPlaceholder() {
      this.numb = 0;
    },
  },
};
</script>
<style>
#pochoir_menu,
#pochoir {
  @apply bg-white  p-0 m-auto;
}

#pochoir_menu {
  @apply p-4 bg-white border-bleu rounded-lg border-2;
}

#pochoir {
  @apply aspect-[21/29.7] sm:aspect-[29.7/21] w-[320px] sm:w-[640px];
  font-family: "Arial Black", Helvetica, sans-serif;
}

.bigtext {
  @apply desktop:text-6xl text-5xl mr-4 desktop:mr-0 inline-block;
}

.slider-pagination-bullets {
  @apply hidden;
}

textarea {
  background-color: transparent !important;
  background: transparent !important;
  box-sizing: border-box;
  display: block;
  border: none !important;
  outline: none !important;
  overflow: hidden;
}
</style>
